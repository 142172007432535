// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBSkCUdxzCj0BtrETjBRZoLWLnHYoTNGXY",
  authDomain: "indbak-e6967.firebaseapp.com",
  databaseURL: "https://indbak-e6967-default-rtdb.firebaseio.com",
  projectId: "indbak-e6967",
  storageBucket: "indbak-e6967.appspot.com",
  messagingSenderId: "744704436242",
  appId: "1:744704436242:web:b2d2e79bc312796ac490cb",
  measurementId: "G-GKT2YRNDZN"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };